var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.userScientificDegree.userScientificDegreeImagePath,
            _vm.userScientificDegree.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.userScientificDegree.userScientificDegreeImageIsDefault &&
          _vm.hasPrivilegeDeleteImage},on:{"changeValue":function($event){_vm.userScientificDegree.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeToken"),"errors":_vm.errors_scientificDegreeToken,"value":_vm.userScientificDegree.scientificDegreeToken,"options":_vm.scientificDegreeTokenOptions,"title":_vm.$t('ScientificDegrees.select'),"imgName":'scientificDegrees.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.scientificDegreeToken = $event;
            _vm.$v.userScientificDegree.scientificDegreeToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-classificationDegreeToken"),"value":_vm.userScientificDegree.classificationDegreeToken,"options":_vm.classificationDegreeTokenOptions,"title":_vm.$t('ClassificationDegrees.select'),"imgName":'classificationDegrees.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.classificationDegreeToken = $event}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-joiningDateTime"),"type":"dateTime","value":_vm.userScientificDegree.joiningDateTime,"title":_vm.$t('UserScientificDegrees.joiningDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.userScientificDegree.joiningDateTime = $event.dateTime}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-obtainingDateTime"),"type":"dateTime","value":_vm.userScientificDegree.obtainingDateTime,"title":_vm.$t('UserScientificDegrees.obtainingDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.userScientificDegree.obtainingDateTime = $event.dateTime}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.userScientificDegree.fullCode,"title":_vm.$t('UserScientificDegrees.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.fullCode = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userScientificDegreeDescriptionAr"),"errors":_vm.errors_userScientificDegreeDescriptionAr,"value":_vm.userScientificDegree.userScientificDegreeDescriptionAr,"title":_vm.$t('UserScientificDegrees.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.userScientificDegreeDescriptionAr = $event;
            _vm.$v.userScientificDegree.userScientificDegreeDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userScientificDegreeDescriptionEn"),"errors":_vm.errors_userScientificDegreeDescriptionEn,"value":_vm.userScientificDegree.userScientificDegreeDescriptionEn,"title":_vm.$t('UserScientificDegrees.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.userScientificDegreeDescriptionEn = $event;
            _vm.$v.userScientificDegree.userScientificDegreeDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userScientificDegreeDescriptionUnd"),"value":_vm.userScientificDegree.userScientificDegreeDescriptionUnd,"title":_vm.$t('UserScientificDegrees.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.userScientificDegreeDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-userScientificDegreeNotes"),"value":_vm.userScientificDegree.userScientificDegreeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userScientificDegree.userScientificDegreeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }